.contact-wrapper {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  overflow: auto;
  z-index: 10;
}

@supports (-moz-appearance:none) {
  .contact-wrapper { 
    background-color: rgba(255, 255, 255, 0.8);
  } 
}

.contact-container {
  margin: auto auto;
  background: white;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  width: 400px;
}

.contact-close {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 0;
}

.contact-back {
  cursor: pointer;
  display: none;
}

.contact-title {
  color: black;
}

.contact-field-title {
  color: black;
  display: block;
  font-size: 14px;
  line-height: 20px;
  padding-top: 16px;
  padding-bottom: 5px;
}

.contact-field {
  display: block;
  width: calc(100% - 26px);
  border-radius: 4px;
  border: solid 1px #c5ccd3;
  background-color: #ffffff;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 15px;
  padding-bottom: 17px;
  margin-right: 20px;
  font-family: Arial, sans-serif;
}

.contact-field-invalid,
.contact-field-invalid a{
  color: #fc0740 !important;
}

.contact-field-error {
  color: #fc0740;
}

.contact-text {
  height: 100px;
}

.contact-message {
  display: block;
  padding-top: 10px;
}

.send-button-wrapper {
  display: inline-block;
  float: right;
}

.send-button {
  cursor: pointer;
  font-size: 15px;
  min-width: 150px;
  height: 46px;
  border: 0 solid;
  border-radius: 4px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
}

.contact-add-photo-button {
  height: 44px;
  position: relative;
  width: 52px;
  border: 1.2px dashed #c5ccd3;
  border-radius: 4px;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  display: inline-flex;

  svg {
    margin: auto auto;
  }
  &.send-pic{
    width: 100%;
    height: 300px;
    align-items: center;
    justify-content: center;
  }
  & .camera-area-button{
    width: calc(100% - 210px);
    height: calc(100% - 150px);
    margin: 0;
  }
}

.contact-photo-preview {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.remove-photo-button {
  position: absolute;
  right: -5px;
  top: -5px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  background: rgba(18, 25, 31, 0.85);
  cursor: pointer;
}

.contact-video-attachment {
  background-color: rgba(0, 0, 0, 0.025);
  display: flex;
  width: 100%;
  height: 100%;
}

.contact-terms-wrapper {
  color: black;
  font-size: 14px;
  margin-top: 16px;
  display: flex;
  text-align: left;
  max-width: 400px;
  border-radius: 4px;
  cursor: pointer;
  &.send-pic{
    margin-bottom: 10px;
  }
}

.contact-terms-checkbox {
  margin-right: 10px;
}

@media screen and (max-width: 600px) {
  .contact-container {
    height: 100%;
    width: calc(100% - 74px);
    margin: unset;
    max-width: 100%;
  }

  .contact-wrapper {
    width: 100%;
    overflow: unset;
    padding: 0;
  }

  .send-button {
    width: 100%;
    display: block;
  }

  .send-button-wrapper {
    display: block;
    float: unset;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .contact-add-photo-button {
    display: flex;
  }

  .contact-back {
    display: inline-block !important;
  }

  .contact-form-send-label {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 840px) {
  body.portrait-view .contact-container {
    height: calc(100vh - 60px);
    width: 345px;
    max-height: 680px;
    min-height: 650px;
    margin: 30px auto 20px;
    transform: scale(0.9);
    border-radius: 30px;
  }
  body.portrait-view .contact-wrapper{
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
  }
}