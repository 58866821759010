.finish-wrapper {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  overflow: auto;
  text-align: center;
  z-index: 10;

  &.success-submit-form{
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 20px;

    & .ScrollbarsCustom{
      max-height: 480px;
    }

    & .container-box{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      margin: 0;
      background: rgba(255,255,255, 0.9);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      position: relative;

      & .container__item{
        display: flex;
        flex-flow: column;
        height: 100%;

        & .finish-back {
          left: 5px;
          top: 5px;
        }
      }
    }
  }
}

@supports (-moz-appearance:none) {
  .finish-wrapper { 
    background-color: rgba(255, 255, 255, 0.9);
  } 
}

.finish-content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
}

.finish-back {
  cursor: pointer;
  position: absolute;
  left: 16px;
  top: 16px;
  color: rgba(18, 25, 31, 0.4);
  text-transform: uppercase;
  font-size: 13px;
}

.finish-back-arrow {
  padding-right: 8px;
}

.finish-text {
  color: #000000;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.finish-sub-text {
  color: #000000;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.finish-next-guide {
  width: 174px;
  height: 120px;
  position: relative;
  cursor: pointer;
}

.finish-next-guide-overlay {
  width: 100%;
  height: 100%;
  position: relative;
  left: 0; top: 0;
  background-color: rgba(18, 25, 31, 0.4);
}

.finish-next-guide-name {
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 150px;
  padding: 12px;
  text-align: center;
  line-height: 18px;
  font-weight: 600;
}

.finish-next-guide-button {
  cursor: pointer;
  height: 46px;
  border: 0 solid;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.finish-demo-block {
  background-color: rgba(255, 255, 255, 0.6);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.finish-demo-content {
  max-width: 460px;
  max-height: 100%;
  margin: auto 25px;
}

.finish-demo-content ul {
  padding-left: 20px;
  margin-top: 4px;
  margin-bottom: 8px;
}

@media(min-width: 481px){
  .finish-wrapper {
    &.success-submit-form{
      padding: 0px;
      & .ScrollbarsCustom{
        max-height: 100%;
      }
      & .container-box{
        height: calc(100% - 80px);
        width: auto;
        margin: 40px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      }
    }
  }
}