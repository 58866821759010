.navigation {
  padding-left: 7.5%;
  padding-right: 7.5%;
  padding-top: 60px;
  padding-bottom: 100px;
}

.navigation-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, max-content));
  grid-column-gap: 30px;
  grid-row-gap: 30px !important;
  padding: initial;
  justify-content: center;
}

.nav-search{
  margin: -20px auto 20px; 
  position: relative; 
  width: 35%;

  &__input{
    height: 40px;
    padding: 8px 16px 8px 46px;
    width: 100%;
    opacity: 1;
    border-radius: 0;
    border: none;
    -webkit-appearance: none;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  }

  &__icon{
    position: absolute; 
    width: 15px; 
    left: 16px; 
    top: 0;
    padding: 12px 0; 
    fill: #757575;
  }
  &__no-result{
    padding: 80px 16px 60px;
    transition: 0.3s;
  }
  & .no-result__image{
    background: url('../images/no-result.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 100%;
    height: 100px;
    opacity: 0.4;
  }
  & .no-result__text{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #757575;
  }
}

@media (max-width: 481px) {
  .nav-search{
    width: 335px !important;
  }
}

@media (max-width: 700px) {

  .navigation {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
  }
    
  .navigation-items {
      grid-template-columns: repeat(auto-fit, minmax(200px, max-content)) !important;
      grid-column-gap: 15px !important;
      grid-row-gap: 30px !important;
    }

    .navigation-item-image {
      width: 200px !important;
      height: 200px !important;
    }

    .navigation-item-title {
      width: 200px !important;
    }

    .navigation-item-subtitle {
      width: 200px !important;
    }
}

@media (max-width: 550px) {

  .navigation {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
  }
    
  .navigation-items {
      grid-template-columns: repeat(auto-fit, minmax(160px, max-content)) !important;
      grid-column-gap: 15px !important;
      grid-row-gap: 30px !important;
    }

    .navigation-item-image {
      width: 160px !important;
      height: 160px !important;
    }

    .navigation-item-title {
      width: 160px !important;
    }

    .navigation-item-subtitle {
      width: 160px !important;
    }
}

.navigation-item {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.navigation-item-image {
  width: 270px;
  height: 270px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.navigation-item-title {  
  display: block;
  padding-top: 20px;
  font-size: 18px;
  line-height: 22px;
  max-width: 270px;
  overflow-wrap: break-word;
  word-break: break-word;
}

.navigation-item-subtitle {  
  display: block;
  color: rgba(18, 25, 31, 0.4);
  padding-top: 10px;
  max-width: 270px;
}

.multipart-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 7px;
  margin-right: 7px;
}

.multipart-navigation-item {
  display: block;
  width: 650px;
  max-width: 100%;
  margin-bottom: 20px;
  height: 230px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.multipart-navigation-item:hover {
  box-shadow: 0 8px 45px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}

.multipart-navigation-item-image {
  width: 200px;
  height: 200px;
  margin: 14px;
  background-size: cover;
  background-position: center;
}

.multipart-navigation-item-content {
  padding: 14px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.multipart-navigation-item-content-part {
  color: #7f8c99;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.multipart-navigation-item-content-title {
  color: #12191f;
  font-size: 28px;
  line-height: 34px;
}

@media (max-width: 700px) {

  .multipart-navigation {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .multipart-navigation-item-image {
    width: 120px;
    height: 120px;
    margin: 10px;
  }  

  .multipart-navigation-item-content-title {
    font-size: 17px;
    line-height: 21px;
  }

  .multipart-navigation-item {
    height: 140px;
    margin-bottom: 10px;
  }  
}

@media(max-width: 1024px){
  .nav-search{
    width: 65%;
  }
}