.container {
    background-color: #7EC5D9;

    width: fit-content;
    line-height: 1;
    border-radius: 4px 4px 0 0;

    position: absolute;
    bottom: -60px;
    left: 0;

    padding: 4.5px 8px;


  transform: translate(-100%, 0);
}

.container > span {
    font-size: 12px;
    font-weight: 700;
    color: #fff;

    display: inline-block;
    text-wrap: nowrap;
}