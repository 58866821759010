.form-container {
  width: 90%;
  padding-bottom: 30px;
  & .contact-terms-checkbox{
    margin-right: 5px;
  }
  & .terms-checkbox{
    &__container{
      line-height: 18px;
      display: flex; 
      flex-wrap: wrap;
      & a{
        padding-right: 5px;
        color: #000;
      }
    }
    &__text{
      padding-right: 5px;
    }
  }
}

.form-container-padding {
  padding-top: 10px;
}

.form-title {
  margin-top: 10px;
}

.form-field {
  margin-top: 10px;
}

.form-field-title {
  margin-top: 10px;
  color: rgb(83, 58, 58);
}

.form-field-input {
  display: block;
  width: 200px;
  max-width: 100%;
  border-radius: 4px;
  border: solid 1px #c5ccd3;
  background-color: #ffffff;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  opacity: 0.9;
}

.form-field-input-invalid {
  border: solid 2px #fc0740;
}

.form-submit-button {
  margin-top: 20px;
  cursor: pointer;
  font-size: 15px;
  width: 120px;
  height: 42px;
  border: 0 solid;
  border-radius: 4px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
}

