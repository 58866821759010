
.gallery-tip-wrapper {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    overflow: auto;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }

  @supports (-moz-appearance:none) {
    .gallery-tip-wrapper { 
      background-color: rgba(255, 255, 255, 0.8);
    } 
  }

.gallery-tip-container {
    position: absolute;
    display: flex;
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.15);
    max-width: 800px;
    max-height: 480px;
    width: calc(100% - 70px);
    height: calc(100% - 70px);
}

.gallery-tip-images-container {
    position: absolute;
    display: flex;
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.15);
    max-width: 800px;
    max-height: 480px;
    width: calc(100% - 70px);
    height: calc(100% - 70px);
}

.gallery-tip-container-portrait {
    max-height: 100%;
}

@media screen and (max-width: 720px) {

    .gallery-tip-container {
        width: calc(100% - 40px) !important;
        height: calc(100% - 40px) !important;
    }

    .gallery-tip-images-container {
        width: 100% !important;
    }

    .gallery-tip-text-container {
        padding: 0 !important;
        padding-bottom: 20px !important;
    }

    .gallery-tip-text {
        padding: 20px !important;
    }

    .gallery-tip-buttons {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .gallery-tip-single-button {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.gallery-tip-text-container {
    background: white;
    padding: 35px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
}

.gallery-tip-text-wrapper {
    align-content: flex-start;
    flex-grow: 1;
    padding-bottom: 10px;
    height: 0;
}

.gallery-tip-text {
    padding: 0;
}

.gallery-tip-buttons {
    align-content: flex-end;
}

.gallery-tip-single-button {
    align-content: flex-end;
}

.tip-image-step {
    width:  100%;
    height: 100%;
    display: flex;
    align-items: center;
    
    .tip-gallery-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;

        .video-container{
            position: relative;
            height: 160px;
        }
    }
}

.gallery-tip-close {
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute; 
    cursor: pointer;
    width: 34px;
    height: 34px;
    border-radius: 17.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 30px;
    top: 0;
}
.gallery-tip-container:has(.gallery-tip-text) {
    .gallery-tip-close {
        right: 13px;
        top: 13px
    }
}

.tip-gallery-image-arrow-wrapper {
    position: absolute; 
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
    width: 34px;
    height: 34px;
    border-radius: 17.5px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tip-gallery-image-next {
    right: 20px;
}

.tip-gallery-image-prev {
    left: 20px;
}
@media (max-width: 500px) and (max-height: 385px) {
    .gallery-tip-image-container{
        width: 30% !important;
    }
    .gallery-tip-text-container{
        width: 70% !important;
    }
}
@media (max-width: 380px){
    .gallery-tip-image-container, .gallery-tip-text-container{
        width: 100% !important;
    }
}