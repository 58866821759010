
.videoStep video {
    width: 100% !important;
    height: 100% !important;
}

.video-controls {
    vertical-align: middle;
    bottom: 55px;
    width: 330px;
    margin: 0 auto;
    position: relative;
    border-radius: 5px;
    background-color: rgba(100, 100, 100, 0.45);
    color: #ffffff;
}

.video-controls-button {
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    margin: 5px;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.video-controls-progress {
    vertical-align: middle;
    display: inline-block;
    padding-left: 10px;
    width: 120px;
    cursor: pointer;
}

.video-controls-time {
    vertical-align: middle;
    display: inline-block;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    height: 100%;
    line-height: 16px;
}

.rc-slider-rail {
    height: 2px !important;
}

.rc-slider-step {
    background-color: white !important;
    height: 2px !important;
}

.rc-slider-track {
    height: 3px !important;
}

.rc-slider-handle {
    border: 0 !important;
    height: 15px !important;
    width: 15px !important;
}

.step #controls{
    position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
}

/*
 *  Usage:
 *
 *    <div class="sk-spinner sk-spinner-wave">
 *      <div class="sk-rect1"></div>
 *      <div class="sk-rect2"></div>
 *      <div class="sk-rect3"></div>
 *      <div class="sk-rect4"></div>
 *      <div class="sk-rect5"></div>
 *    </div>
 *
 */
.sk-spinner-wave.sk-spinner {
    margin: 0 auto;
    width: 50px;
    height: 30px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: inline-block;
}
.sk-spinner-wave div {
    background-color: #70C3D6;
    height: 100%;
    width: 6px;
    display: inline-block;
    margin:1px;
    -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
    animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}
.sk-spinner-wave .sk-rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.sk-spinner-wave .sk-rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.sk-spinner-wave .sk-rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.sk-spinner-wave .sk-rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
@-webkit-keyframes sk-waveStretchDelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}
@keyframes sk-waveStretchDelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}

@media screen and (min-device-width:1024px){
    .rc-slider-handle:hover {
        border: 0 !important;
    }    
}

.rc-slider-handle:active {
    border-color: #ffffff !important;
    -webkit-box-shadow: 0 0 5px #ffffff !important;
    box-shadow: 0 0 5px #ffffff !important;
}


@media (max-width: 400px) {

    .video-controls {
        vertical-align: middle;
        bottom: 45px;
        width: 250px;
        margin: 0 auto;
        position: relative;
        border-radius: 5px;
        background-color: rgba(100, 100, 100, 0.45);
        color: #ffffff;
    }

    .video-controls-button {
        width: 20px;
        height: 20px;
    }
    

    .video-controls-progress {
        vertical-align: middle;
        display: inline-block;
        padding-left: 5px;
        width: 80px;
        cursor: pointer;
    }
    
    .video-controls-time {
        vertical-align: middle;
        display: inline-block;
        height: 30px;
        padding-left: 10px;
        font-size: 14px;
        height: 100%;
        line-height: 14px;
    }
}