.slider {
  height: 100% !important;
  width: 100% !important;

}

.slider-list {
  height: 100% !important;
  width: 100% !important;

}

.slider-frame {
  height: 100% !important;
  width: 100% !important;
}

.slider-slide {
  height: 100% !important;
  width: 100% !important;
}

.instructions {
  position: relative;
  width: 100%;
  height: 100%;
}

.slick-track {
  width: 100%;
  height: 100%;
}

.instructions-slide {
  width: 100%;
  height: 100%;
}