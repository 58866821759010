
.webStepWrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.webStep {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}

.webStepHidden {
    display: none;
}

.webStep iframe {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}