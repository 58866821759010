.intro {
    width: 80%;
    max-width: 600px;
    padding-bottom: 30px;
}

.intro-portrait-wrapper {
    display: flex;
    flex: 1;
    flex-grow: 1;
}

.intro-content-wrapper {
    max-height: 320px;
}

@media screen and (max-width: 340px) {
   .start-wrapper {
    width: 100%;
    max-width: 100%;
   }
}

.intro-content {
    width: calc(100vw - 60px);
}

.intro-start-button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 46px;
    border: 0 solid;
    border-radius: 4px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
    padding: 0 24px;
    margin-bottom: 16px;
    margin-top: 16px;
    text-transform: uppercase;
    max-width:100%;
    overflow: hidden;
    span {
        width: 100%;
        flex-grow: 1;
        padding-right: 9px;
        height: 13px;
        word-break: break-word;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.intro-landscape-half-wrapper {
    width: 50%;
    height: 100%;
    display: inline-block;
    position: relative;
    overflow: hidden;
}

.intro-landscape-details-box {
    position: relative;
}

.intro-landscape-details-wrapper {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 87.5%;
    width: 85%;
    margin: 0 auto;
}

.intro-landscape-guide-description {
    font-size: 15px;
    line-height: 20px;
    padding-top: 16px;
    display: block;
    white-space: wrap;
    max-height: 100%;
}

.intro-landscape-guide-subtitle {
    font-size: 22px;
    text-shadow: 0.5px 0 0;
    line-height: normal;
    padding-top: 16px;
    display: block;
    white-space: wrap;
    max-height: 100%;
}

.text-content {
    padding-top: 16px;
}

@media screen and(max-width: 840px) {
    .intro-landscape-details-wrapper {
        padding: 0 30px;
        width: 100%;
        height: 100%;
    }
    .wrapper-header {
        padding-top: 10px;
    }
    .intro-content-wrapper,
    {
        min-height: 350px;
    }
    .content-text-scrollable {
        max-height: 301px;
    }
}

@media screen and (min-width: 841px) {
    .intro-content {
        max-width: 340px;
    }
    .content-text-scrollable {
        max-height: 270px;
        overflow: auto;
    }
}

.content-text-scrollable {
    overflow: hidden auto;
    min-height: 46px;
    padding-right: 5px;
    &::-webkit-scrollbar {
        width: 5px;
        scrollbar-width: thin;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
}

.intro-content-back-arrow {
    position: absolute;
    top: 10px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    text-align: center;
    padding: 10px;
    padding-left: 0;
    cursor: pointer;
}

.intro-details-action-icons {
    right: 30px;
    top: 14px;
    height: 23px;
    display: flex;
}

.intro-details-action-icon {
    display: inline-block;
    padding-left: 6px;
    padding-right: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    object-fit: contain;
}

.intro-details-action-icon-language {
    width: 15px;
    height: 23px;
}

@media screen and (max-width: 600px), screen and (max-height: 400px) {
    .intro-landscape-guide-description,
    .intro-landscape-guide-subtitle{
        display: none;
    }
}

.intro-portrait-guide-description {
    font-size: 15px;
    line-height: 20px;
    padding-top: 16px;
    display: block;
    white-space: wrap;
}

.intro-portrait-guide-subtitle {
    font-size: 22px;
    text-shadow: 0.5px 0 0;
    line-height: normal;
    padding-top: 16px;
    display: block;
    white-space: wrap;
}

.intro-logo {
    max-height: 30px;
    max-width: 140px;
    object-fit: contain;
}

.small-intro-logo {
    height: 16px;
    padding-bottom: 10px;
}

.start-wrapper {
    width:100%;
}

.intro-icons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 30px;
}

.intro-icons > *:nth-child(1):nth-last-child(1) {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 3px;
    .intro-icon-text {
        line-height: normal !important;
    }
}

.intro-icons > *:nth-child(1),
.intro-icons > *:nth-child(2) {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 3px;
    .intro-icon-text {
        line-height: normal !important;
    }
}

.intro-icons > *:nth-child(1):nth-last-child(3),
.intro-icons > *:nth-child(2):nth-last-child(2),
.intro-icons > *:nth-child(3):nth-last-child(1) {
    display: flex;
    width: calc(100% / 3 - 19px);
    align-items: center;
    justify-content:center;
    gap: 3px;
    .intro-icon-text {
        line-height: normal !important;
    }
}

.intro-icons:has(> *:nth-child(4):nth-last-child(1)) {
    gap: 16px;
}

.intro-icons > *:nth-child(1):nth-last-child(4),
.intro-icons > *:nth-child(2):nth-last-child(3),
.intro-icons > *:nth-child(3):nth-last-child(2),
.intro-icons > *:nth-child(4):nth-last-child(1) {
    display: flex;
    width: calc(100% / 4 - 10px);
    flex-direction: column;
    align-items: center;
    gap: 3px;
    .intro-icon-text {
        display: inline-block;
        vertical-align: middle;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
}
@media screen and (min-width: 500px) and(max-width: 840px) {
    .intro-icons {
        gap: 1.0875em;
    }
    .intro-icons > *:nth-child(1):nth-last-child(3),
    .intro-icons > *:nth-child(2):nth-last-child(2),
    .intro-icons > *:nth-child(3):nth-last-child(1) {
        width: fit-content;
    }
    .intro-icons > *:nth-child(1):nth-last-child(4),
    .intro-icons > *:nth-child(2):nth-last-child(3),
    .intro-icons > *:nth-child(3):nth-last-child(2),
    .intro-icons > *:nth-child(4):nth-last-child(1) {
        width: fit-content;
    }
}

.intro-icon-svg {
    display: flex;
    align-items: center;
}

.intro-icon-text {
    display: inline-block;
    vertical-align: middle;
    text-transform: capitalize;
    text-wrap:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.small-intro-button {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 21px);
    width: 42px;
    height: 42px;
    border-radius: 4px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
    border: 0;
    cursor: pointer;
}

.wrapper-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-height: 650px) {
    .intro-content-wrapper {
        min-height: 80px;
    }
    .content-text-scrollable {
        height: 210px;
    }
}

@media screen and (max-height: 551px) {
    .content-text-scrollable {
        height: 166px;
    }
}

@media screen and (max-height: 480px) {
    .content-text-scrollable {
        height: 130px;
    }
}

@media screen and (max-width: 320px) {
    .content-text-scrollable {
        max-height: 303px;
    }
}