.intro.powered-by{
  position: absolute;
  bottom: 5px;
  padding-left: initial;
  padding-right: initial;
  padding-top: initial;
  margin-bottom: initial;
  padding-bottom: initial;
}

.powered-by {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;
  margin-bottom: -40px;
  color: rgba(18, 25, 31, 0.4);
  font-size: 10px;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
}

.powered-by-brand{
  padding-left:3px;
  font-size: 14px;
  font-weight: 600;
  color: initial;
}

@media (max-width: 700px) {

  .powered-by {

    padding-top: 10px;
    margin-bottom: -10px;
  }
}