.guide-navigation-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.guide-navigation {
  display: flex;
  height: 70px;
  box-shadow: 0 -7px 8px -5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.guide-navigation-controls {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  z-index: 0;
}

.guide-navigation-controls-button {
  display: inline-block;
  pointer-events: all;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
}

.navigation-next-button,
.navigation-prev-button {
  float: right;
  cursor: pointer;
  margin: auto 14px;
  font-size: 15px;
  padding-right: 24px;
  padding-left: 24px;
  height: 46px;
  border: 0 solid;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  white-space: nowrap;
}

.navigation-prev-button {
  float: left;

  border: 1px solid;
  width: 46px;
  height: 46px;
  padding-right: 15px;
  padding-left: 15px;
}
.navigation-next-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-next-button-small {
  width: 46px;
  height: 46px;
  padding-right: 15px;
  padding-left: 15px;
}
