.hideProgress .progressbar-wrapper{
  display: none;
}

.progressbar-wrapper {
  line-height: 0px;
  -webkit-user-select: none;       
  -moz-user-select: none;
  -ms-user-select: none; 
  user-select: none;
  
  &:hover{
    .fade-in-out-exit-done{
      opacity: 1;
    }
  }
}

.progressbar {
  display: inline-flex;
  width: 100%; 
  height: 6px;
  background: rgba(0, 0, 0, 0.15);
}

.progressbar-inner {
  height: 6px;
}

.progressbar-bubble-wrapper {
  position: relative;
  -webkit-user-select: none;       
  -moz-user-select: none;
  -ms-user-select: none; 
  user-select: none;
  pointer-events: none;
}

.progressbar-bubble {
  position: relative;
  top: -60px;
  right: -1px;
  padding-bottom: 6px;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}