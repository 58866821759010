body.portrait-view .step{
  &__rtl .MuiBox-root{
    flex-direction: column;
  }
}
.step {
    width: 100%;
    height: 100%;
    background-size: cover;
}

.step:has(.text-step-wrapper) {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.2) 100%);
    pointer-events: none;
  }
}

.step-image {
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto auto;
  position: relative;
  width: 100%;
  height: 100%;
}

.step-gallery{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & > div{
    height: 100%;
    backdrop-filter: blur(20px);
  }
  & .gallery-container{
    padding: 60px 20px 20px 20px;
    height: calc(100% - 80px);
    width: calc(100% - 40px);
    margin: 0;

    & .gallery-item{
      width: auto;
      height: auto;
      margin: 0;
      position: absolute;
      & .playImg{
        background-size: 40px;
      }
    }
    & .tip-image-step{
      justify-content: center;
      & > div{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        & .tip-gallery-image{
          width: calc(50% - 10px);
          margin: 5px;
          height: 160px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
      }
    }
  }
  & .gallery-title{
    display: none;
  }
  & .gallery-items-container{
    height: 330px;
    & .ScrollbarsCustom-Content{
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      overflow-y: scroll;
      & .gallery-item{
        max-width: 250px;
        height: 155px;
        margin: 5px;
      }
    }
  }
}

.step-landscape {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.step-title {
  font-size: 36px;
  word-wrap: break-word;
  white-space: pre-wrap;
  display: block;
  padding-bottom: 16px;
}

.step-title:first-letter {
  text-transform: uppercase;
}

.step-text {
    word-wrap: break-word;
    white-space: pre-wrap;
    display: flex;
    width: 100%;
}

.step-content-logo {
  position: absolute;
  right: 20px;
  top: 15px;
  width: auto;
  max-height: 30px;
  max-width: 120px;
  z-index: 1;
  object-fit: contain;
}

.text-step-wrapper {
  position: relative;
  z-index: 100;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 7.5%;
  padding-bottom: 15%;
  word-wrap: break-word;
  white-space: pre-wrap;
  display: block;
}

.text-step-title {
  word-wrap: break-word;
  white-space: pre-wrap;
  display: block;
  padding-bottom: 15px;
}

.text-step-title:first-letter {
  text-transform: uppercase;
}

.text-step-text {
  display: block;
  height: 650px;
}

.step-content-wrapper {
  padding: 50px 30px 30px;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.step-content-wrapper-portrait {
  padding: 20px;
  height: 100%;
}

.content-step-scrollable,
.content-step-text-scrollable {
  overflow: hidden auto;
  min-height: 46px;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: thin;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
}
@media screen and (min-width: 841px) {
  .text-step-text {
    max-height: 250px;
  }
}

@media screen and (max-height: 865px) {
  .text-step-text {
    max-height: 550px;
  }
}

@media screen and (max-height: 765px) {
  .text-step-text {
    max-height: 500px;
  }
}
@media screen and (max-height: 700px) {
  .text-step-text {
    max-height: 450px;
  }
}

@media screen and (max-height: 655px) {
  .text-step-text {
    max-height: 400px;
  }
}

@media screen and (max-height: 600px) {
  .text-step-text {
    max-height: 350px;
  }
}

@media screen and (max-height: 555px) {
  .text-step-text {
    max-height: 275px;
  }
}

@media (max-width: 520px) {
  .step-text {
    margin-bottom: 1em;
  }
}