.topbar-container-sticky {
    height: 60px;
    text-align: center;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.topbar-container {
    height: 60px;
    position: relative;
    left: 0;
    top: 0;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
body.portrait-view .topbar-container,
body.portrait-view .topbar-container-sticky{
    display: none;
}

.topbar-icon {
    height: 60px;
    align-items: center;
    justify-content: center;
    justify-self: center;
    display: flex;
}

.topbar-icon-padding {
    padding-left: 15px;
    padding-right: 15px;
}

.topbar-logo {
    height: auto;
    max-width: 300px;
    width: auto;
    max-height: 30px;
    object-fit: contain;
}

.topbar-left-icons {
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
}

.topbar-right-icons {
    top: 0;
    right: 0;
    padding-right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    position: absolute;
}

.topbar-contact-icon {
    display: none;
}

.keter
    {
    .topbar-contact-icon {
        display: flex !important; 
    }
}

@media (max-width: 700px) {

    .topbar-contact-icon {
        display: flex !important;
    }

    .topbar-logo {
        max-width: 120px !important;
    }
}