.materials {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.materials-list-wrapper {
  width: 100%;
  padding: 50px 30px 30px;
  margin-bottom: 20px;
}

.materials-images-list-wrapper {
  width: 100%;
  padding: 50px 30px 30px;
  margin-bottom: 20px;
  overflow: hidden;
}

.materials-list {
  height: 100%;
  margin: 0 auto;
  color: black;
  padding: 0;

  .materials-header {
    padding-bottom: 16px;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  li {
    list-style-type: none;
  }
}

.material-content {
  vertical-align: middle;
  display: inline-block;
  width: 90%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 7.5%;
}

.material-number {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  min-width: 40px;
  display: inline-block;
}

.materials-image-item {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  //width: 140px;
  font-size: 17px;
  line-height: 20px;
}

.materials-image-item-image {
  width: 140px;
  height: 140px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center;
}

.materials-image-item-text {
  margin-top: 8px;
  max-width: 140px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

.materials-image-item-quantity {
  margin-top: 5px;
  opacity: 0.75;
}

.content-materials-scrollable {
  overflow: hidden auto;
  min-height: 46px;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: thin;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
}

.materials-images-header {
   padding-bottom: 16px;
}

@media screen and (max-width: 500px) {
  .materials-images-list-wrapper {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
    width: 360px;
  }

  .materials-image-item {
    margin-right: 18px;
    margin-left: 18px;
  }

  .materials-images-header {
    margin-left: 18px;
  }
}
