/*@font-face {
  font-family: Manrope ExtraLight;
  src: url('/fonts/Manrope-ExtraLight.woff') format('woff'),
}

@font-face {
  font-family: Manrope Bold;
  src: url('/fonts/Manrope-Bold.woff') format('woff'),
}*/

@font-face {
  font-family: 'MikadoRegular';
  src: url('/fonts/MikadoRegular/MikadoRegular.eot');
  src: url('/fonts/MikadoRegular/MikadoRegular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/MikadoRegular/MikadoRegular.woff2') format('woff2'),
      url('/fonts/MikadoRegular/MikadoRegular.woff') format('woff'),
      url('/fonts/MikadoRegular/MikadoRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MikadoLight';
  src: url('/fonts/MikadoRegular/MikadoLight.eot');
  src: url('/fonts/MikadoRegular/MikadoLight.eot?#iefix') format('embedded-opentype'),
      url('/fonts/MikadoRegular/MikadoLight.woff2') format('woff2'),
      url('/fonts/MikadoRegular/MikadoLight.woff') format('woff'),
      url('/fonts/MikadoRegular/MikadoLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsRegular';
  src: url('/fonts/Poppins/Poppins-Regular.eot');
  src: url('/fonts/Poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
      url('/fonts/Poppins/Poppins-Regular.woff') format('woff'),
      url('/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsThin';
  src: url('/fonts/Poppins/Poppins-Thin.eot');
  src: url('/fonts/Poppins/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Poppins/Poppins-Thin.woff2') format('woff2'),
      url('/fonts/Poppins/Poppins-Thin.woff') format('woff'),
      url('/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Heebo';
  src: url('/fonts/Heebo/Heebo-Thin.eot');
  src: url('/fonts/Heebo/Heebo-Thin.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Heebo/Heebo-Thin.woff2') format('woff2'),
      url('/fonts/Heebo/Heebo-Thin.woff') format('woff'),
      url('/fonts/Heebo/Heebo-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo';
  src: url('/fonts/Heebo/Heebo-Regular.eot');
  src: url('/fonts/Heebo/Heebo-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Heebo/Heebo-Regular.woff2') format('woff2'),
      url('/fonts/Heebo/Heebo-Regular.woff') format('woff'),
      url('/fonts/Heebo/Heebo-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo';
  src: url('/fonts/Heebo/Heebo-Bold.eot');
  src: url('/fonts/Heebo/Heebo-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Heebo/Heebo-Bold.woff2') format('woff2'),
      url('/fonts/Heebo/Heebo-Bold.woff') format('woff'),
      url('/fonts/Heebo/Heebo-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Hebrew';
  src: url('/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBold.eot');
  src: url('/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBold.woff2') format('woff2'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBold.woff') format('woff'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Hebrew';
  src: url('/fonts/OpenSansHebrew/OpenSansHebrew-Light.eot');
  src: url('/fonts/OpenSansHebrew/OpenSansHebrew-Light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-Light.woff2') format('woff2'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-Light.woff') format('woff'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Hebrew';
  src: url('/fonts/OpenSansHebrew/OpenSansHebrew-Bold.eot');
  src: url('/fonts/OpenSansHebrew/OpenSansHebrew-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-Bold.woff2') format('woff2'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-Bold.woff') format('woff'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Hebrew';
  src: url('/fonts/OpenSansHebrew/OpenSansHebrew-Regular.eot');
  src: url('/fonts/OpenSansHebrew/OpenSansHebrew-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-Regular.woff2') format('woff2'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-Regular.woff') format('woff'),
      url('/fonts/OpenSansHebrew/OpenSansHebrew-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;700&display=swap');

html {
  background: transparent;
  height: 100%;
}

body {
  font-family: 'Manrope', sans-serif;
  font-weight: 200;
  margin: 0 auto;
  height: 100%;
  font-size: 15px;
  line-height: 20px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
body.embedded{
  overflow: hidden;
}

:focus {
  outline: 0;
}

#root {
  height: 100%;
}
.MuiCircularProgress-colorPrimary{
  color: #a7ccd5 !important;
}

.MuiTypography-body1 {
  font-family: 'Manrope', sans-serif !important;
  font-weight: 700;
  font-size: 14px !important;
  line-height: 30px !important;
}

.MuiMenuItem-root {
  min-height: auto !important;
}

.title-font {
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
}

.default-title {
  font-size: 35px;
  line-height: 45px;
}

.default-responsive-title {
  font-size: 35px;
  line-height: 45px;
}

@media (max-width: 700px) and (max-height: 500px) {

  .default-responsive-title {
    font-size: 25px;
    line-height: 30px;
  }
}

.large-title {
  font-size: 45px;
  line-height: 55px;
}

.small-title {
  font-size: 15px;
  line-height: 20px;
}

.medium-title {
  font-size: 20px;
  line-height: 25px;
}

.default-text {
  font-size: 15px;
  line-height: 20px;
}

.small-bold-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
}

.small-text {
  font-size: 13px;
  line-height: 20px;
}

.default-button {
  font-size: 16px;
  border: 1px solid black;
  padding: 10px;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 500px;
  cursor: pointer;
}

.default-main-button {
  cursor: pointer;
  font-size: 15px;
  min-width: 250px;
  height: 42px;
  border: 0 solid;
  border-radius: 4px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
}

.default-sub-button {
  font-size: 14px;
  border: 1px solid black;
  background-color: white;
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.full-height {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.FullScreen {
  background-color: black;
  margin: 0 auto;
}

.link {
  color: black;
}

.direction {
  direction: ltr;
  text-align: left;
}

.basic-border {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.wrapper {
  display: block;
}

.move-animation-width {
  transition: width 300ms ease-in-out;
}

.move-animation-left {
  transition: left 150ms ease-in-out;
}

.fade-guide-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-guide-enter-active{
  opacity: 1;
  display: block;
  transform: translateX(0);
  transition: opacity 300ms;
}

.fade-in-enter {
  opacity: 0.4;
}

.fade-in-enter-active {
  opacity: 1;
  display: block;
  transition: opacity 300ms;
}

.quick-fade-in-enter {
  opacity: 0.7;
}

.quick-fade-in-enter-active {
  opacity: 1;
  display: block;
  transition: opacity 300ms;
}

.popup-open {
  visibility: visible;
  opacity: 1;
  transition: opacity 200ms;
}

.popup-close {
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms, visibility 200ms;
}

.top-bottom-enter {
  transform: translateY(-20px);
}

.top-bottom-enter-active {
  transform: translateY(0);
  transition: transform 200ms;
}

.bottom-top-enter {
  transform: translateY(20px);
}

.bottom-top-enter-active {
  transform: translateY(0);
  transition: transform 200ms;
}

.left-right-quick-enter {
  transform: translateX(-20px);
}

.left-right-quick-enter-active {
  transform: translateX(0);
  transition: transform 250ms;
}

.right-left-quick-enter {
  opacity: 0.25;
  transform: translateX(40px);
}

.right-left-quick-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 500ms, opacity 500ms;
}

.right-left-medium-enter {
  transform: translateX(20px);
}

.right-left-medium-enter-active {
  transform: translateX(0);
  transition: transform 500ms;
}

.fade-in-out-enter {
  opacity: 0;
}

.fade-in-out-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-in-out-exit {
  opacity: 1;
}

.fade-in-out-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.fade-in-out-exit-done {
  opacity: 0;
  transition: opacity 200ms;
}

.ScrollbarsCustom-Wrapper {
  right: 0 !important;
}

.ScrollbarsCustom-TrackY {
  width: 5px !important; 
  height: calc(100% - 10px) !important;
  top: 5px !important;
}

.ScrollbarsCustom-ThumbY {
  background: rgba(0, 0, 0, 0.1) !important;
}

.ScrollbarsCustom-TrackX {
  height: 5px !important; 
  width: calc(100% - 5px) !important;
  left: 5px !important;
}

.ScrollbarsCustom-ThumbX {
  background: rgba(0, 0, 0, 0.1) !important;
}

.ScrollbarsCustom-Content {
  width: 100%;
  height: 100%;
}
// preload

#mpreload {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
}

#mpreload span {
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  -webkit-animation: mpreload 1s infinite ease-in-out;
          animation: mpreload 1s infinite ease-in-out;
}

#mpreload span:nth-child(1) {
  left: 0px;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

#mpreload span:nth-child(2) {
  left: 15px;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

#mpreload span:nth-child(3) {
  left: 30px;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

#mpreload span:nth-child(4) {
  left: 45px;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@keyframes mpreload {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: rgba(214, 45, 32, .5);
  }
  25% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    background: rgba(255, 167, 0, 0.5);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    background: rgba(0, 135, 68, 0.5);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: rgba(0, 87, 231, 0.5);
  }
}
@-webkit-keyframes mpreload {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: rgba(214, 45, 32, 0.5);
  }
  25% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    background: rgba(255, 167, 0, 0.5);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    background: rgba(0, 135, 68, 0.5);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: rgba(0, 87, 231, 0.5);
  }
}

@media screen and (min-width: 840px) {
  body.portrait-view .CookieConsent{
    display: none !important;
  }
  body.portrait-view .guide-topbar-container{
    border-radius: 30px 30px 0 0;
  }
  body.portrait-view .guide-navigation,
  body.portrait-view .step,
  body.portrait-view .guide-navigation-controls,
  body.portrait-view .slider-frame{
    border-radius: 0 0 30px 30px;
  }
  body.portrait-view .finish-wrapper.success-submit-form,
  body.portrait-view .finish-wrapper,
  body.portrait-view .guide-wrapper,
  body.portrait-view .guide-wrapper > div,
  body.portrait-view .gallery-tip-wrapper{
    border-radius: 30px;
  }
}