.guide-container {
  width: 800px;
  height: 480px;
  position: relative;
  margin: 0 auto;
  margin-top: -50px;
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.15);
}

body.portrait-view{
  background-image: linear-gradient(90deg, #F6F6F6 0%, #F3EEE7 100%);
}

body.portrait-view .guide-container{
  height: calc(100vh - 60px);
  width: 415px;
  max-height: 740px;
  min-height: 700px;
  margin: 30px auto 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  transform: scale(0.9);
  overflow: hidden;
}

.guide-container-full {
  width: 100%;
  height: 100%;
}

.guide-container-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.guide-container-wrapper-full {
  width: 100%;
  height: 100%;
}

.guide-wrapper {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.guide-container-prev-guide {
  width: 68px;
  height: 68px;
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  top: 40%;
  justify-content: center;
  cursor: pointer;
}

.guide-container-next-guide {
  width: 68px;
  height: 68px;
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.15);
  top: 40%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}