.gallery-container {
    padding-top: 35px;
    padding-bottom: 45px;
    padding-left: 10%; 
    padding-right: 10%;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
    height: 300px;
    white-space: nowrap;
    width: 80%;
    margin-bottom: 20px;
    
    & .emodji{
        width: 100%;
        position: absolute;
        bottom: 20px;
        z-index: 2;
        display: flex;
        justify-content: center;
        left: 0;
        & .like-icon{
            background: #5e91ff;
        }
        & .heart-icon{
            background: #f41e42;
        }
        & .like-icon, .heart-icon{
            display: flex;
            margin-right: 10px;
            border-radius: 6px;
            overflow: hidden;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            min-width: 36px;

            &:hover{
                & #heart, #like{
                    transform: scale(1.1);
                }
            }
            &.empty{
                min-width: auto;
                & .count{
                    display: none;
                }
            }
        }
        & .count {
            padding: 0 7px 2px 2px;
            display: flex;
            align-items: center;
        }
    }

    & #heart, #like{
        width: 25px;
        height: 25px;
        transition: 0.3s;
        &.isClicked{
            opacity: 1;
        }
    }
}

.gallery-title {
    margin-bottom: 15px;
}

.gallery-items-container {
    height: 230px;
    white-space: nowrap;
    width: 100%;
}

.gallery-item {
    max-width: 300px;
    height: 225px;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    overflow: hidden;
}
.video-container{
    position: relative;
    height: 225px;
    width: auto;
    max-width: 300px;
    min-width: 110px;
    text-align: center;

    & video{
        height: 100%;
        width: auto;
    }
    & .playImg{
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-image: url('../images/play.png');
        background-size: 60px;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
    }
}


@media (max-width: 700px) {

    .gallery-container {
        padding-top: 20px;
        padding-bottom: 20px;
        height: 225px;
    }

    .gallery-items-container {
        height: 155px;
    }
    
    .gallery-item {
        width: auto;
        height: 150px;
    }

    .gallery-title {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .video-container{    
        & .playImg{
            background-size: 40px;
        }
    }
}
