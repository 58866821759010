.split-step-wrapper {
    top: 10%;
    padding-left: 10px; 
    padding-right: 10px;
    max-width: 100%;
    position: relative;
}

.split-step-title {
    text-align: center;
    width: 90%;    
    padding-left: 5%;
    font-size: 25px;
    line-height: 30px;
}

.split-step-decisions-container {
    margin: 0 auto;
    max-width: 80%;
    text-align: center;
    padding-bottom: 25px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 19px;
}

.split-step-image-decisions-container {
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.split-step-decision {
    margin: 0 auto;
    cursor: pointer;
    display: block;
    padding: 10px;
    display: block;
    width: 225px;
    min-height: 42px;
    border: 0 solid;
    border-radius: 4px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
}

.split-step-image-decision-wrapper {
    padding: 25px;
    display: inline-block;
    text-align: center;
    width: 140px;
    padding-bottom: 0;
    cursor: default;
}

.split-step-image-decision-image {
    width: 140px;
    height: 140px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

@media (max-width: 700px) {

    .split-step-image-decision-wrapper {
        padding: 15px;
        display: inline-block;
        text-align: center;
        width: 120px;
        padding-bottom: 0;
    }
    
    .split-step-image-decision-image {
        width: 120px;
        height: 120px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
        background-size: cover;
        background-position: center;
    }
    .split-step-image-decisions-container{
        flex-wrap: wrap;
    }
}

.split-step-image-decision-title {
    padding-top: 8px;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
    .split-step-wrapper{
        transform: scale(0.9);
    }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .split-step-wrapper{
        transform: scale(0.9);
    }
}

.step:has(.split-step-wrapper) {
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.2) 100%);
    }
}