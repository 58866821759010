.takephoto-container {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.takephoto-text {
  padding-top: 10px;
}

.takephoto-sub-text {
  padding-top: 5px;
}

.camera-container {
  width: 200px;
  height: 160px;
  max-width: 80%;
  margin-top: 20px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.camera-area {
  width: 100%;
  height: 100%;
}

.camera-area-button {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  background-image: url('../images/camera-big.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 17.5px;
  margin-top: 12.5px;
}

.camera-area-image {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.camera-area-overlay {
  width: 100%;
  height: 100%;
  color: white;
  background-color: rgba(18, 25, 31, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.takephoto-terms {
  margin-top: 20px;
  width: 260px;
  max-width: 80%;
  text-align: center;
}

.camera-loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-buttons-container{
  position: relative;
    width: 32px;
    top: -160px;
    right: -18px;
    float: right;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}