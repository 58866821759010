.gallery-widget {
  display: flex;
  flex-direction: column;
  padding-inline: 20px;
  padding-block: 20px;
  overflow-x: hidden;
}

.grid-container {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); //shold be inline style
}

.grid-item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 225px;

  & img {
    width: 100%;
    height: 100%;
    display: block;
    max-height: 225px;
    object-fit: contain;
  }

  & .emodji-icon {
    width: 100%;
    position: absolute;
    bottom: 20px;
    z-index: 2;
    display: flex;
    justify-content: center;
    left: 0;
    & .like-icon {
      background: #5e91ff;
    }
    & .heart-icon {
      background: #f41e42;
    }
    & .like-icon,
    .heart-icon {
      display: flex;
      margin-right: 10px;
      border-radius: 6px;
      overflow: hidden;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      min-width: 36px;

      &:hover {
        & #heart,
        #like {
          transform: scale(1.1);
        }
      }
      &.empty {
        min-width: auto;
        & .count {
          display: none;
        }
      }
    }
    & .count {
      padding: 0 7px 2px 2px;
      display: flex;
      align-items: center;
    }
  }
  & #heart,
  #like {
    width: 25px;
    height: 25px;
    transition: 0.3s;
    &.isClicked {
      opacity: 1;
    }
  }
}

.widget-card {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-height: 225px;
}

.gallery-widget_video {
  max-width: 300px;
  max-height: 225px;
  height: inherit;
  position: relative;
  width: auto;
  text-align: center;

  & video {
    height: 100%;
    width: 100%;
    // max-height: ;
  }
  & .playImg {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-image: url("../images/play.png");
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }
}

.empty_data_container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.empty_data_card {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
