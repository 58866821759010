.home-banner {
    height: 350px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

body.portrait-view .home-banner{
    display: none;
}

.topbar-padding {
    padding-top: 60px;
}

@media (max-width: 700px) {
    .home-banner {
        height: 210px;
    }
    .banner-title {
        font-size: 30px;
        line-height: 35px;
    }
    .home-contact-button {
        display: none !important;
    }
}

.home-banner-content {
    width: 100%;
    height: 100%;
    background-color: rgba(18, 25, 31, 0.4);
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-wrap: break-word;
}

.banner-title {
    padding-bottom: 20px;
    max-width: 650px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: capitalize;
}

.banner-top-subtitle {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0.7;
}

.banner-subtitle {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 650px;
}

.footer {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 12px;
    color: #12191f;
}

.home-contact-button {
    width: 71px;
    height: 71px;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-items: center;
    text-align: center;
    cursor: pointer;
}

.home-contact-button-icon-wrapper {
    width: 100%;
}

.keter {
    .home-contact-button {
        display: none; 
    }
}