.guide-topbar-container {
    height: 50px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.guide-topbar-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    position: absolute;
    top: 0;
}

.guide-topbar-icon {
    cursor: pointer;
    position: absolute;
    padding-left: 15px;
    padding-right: 15px;
}

.guide-topbar-left-icon {
    left: 0;
}

.guide-topbar-right-icon {
    right: 0;
}

.guide-topbar-logo {
    height: auto;
    max-width: 300px;
    width: auto;
    max-height: 30px;
}

@media (max-width: 700px) {

    .guide-topbar-logo {
        max-width: 120px !important;
    }
}