
.video-player-play {
    position: absolute;
    vertical-align: middle;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 33%;
    height: 33%;
    top: 33%;
    left: 33%;
    background-image: url('../images/play.png');
}