.tips-icon {
    position: absolute;
    pointer-events: all;
    cursor: pointer;
    height: auto;
    min-width: 40px;
    z-index: 1;
}

.tips-icon-default {
  width: 10vmin !important;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  min-width: 55px;
  max-width: 65px;
  z-index: 1;
}

.pulse {
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse-animation 1.5s infinite;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.115);
  }
  100% {
    transform: scale(1);
  }
}

.pulse-legacy {
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse-animation-legacy 1.5s infinite;
}

@keyframes pulse-animation-legacy {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  70% {
    transform: translate(-50%, -50%) scale(1.115);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.text-tip-container-wrapper {
  width: 100%;
  height: 100%;
}

.text-tip-container {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  z-index: 1;
}

.text-tip-content {
  background-color: white;
  color: black;
  display: flex;
  max-width: 400px;
  max-height: 80%;
  font-size: 15px;
  line-height: 20px;
  padding: 18px;
  margin: auto auto;
  overflow: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.2);
}

.content-tip-wrapper {
  display: block;
  text-align: center;
  min-width: 90px;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 7px;
  padding-bottom: 7px;
  height: 35px;
  margin-top: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.content-tips-wrapper {
  padding-bottom: 40px;
}

.content-text-tip {
  display: table;
  padding-top: 20px;
  max-width: 90%;
}

.content-text-tip-icon {
  display: table-cell;
}

.content-text-tip-text {
  display: table-cell;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  font-size: 13px;
  line-height: 16px;
  max-width: 50%;
}

.content-tip-button-text {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}